import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入 element-plus UI组件框架
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'

// 引入 flexible 适应多种屏幕的js框架
import '../public/static/flexible'

// 引入 UserInfoGlobal 自定义的用户信息实体类
import UserInfoGlobal from './utils/UserInfoGlobal'

// 引入 js-cookie 管理cookie的插件
import Cookies from 'js-cookie'

// 引入 base 自定义样式
import '/src/assets/css/base.css'
// 引入jquery
// import $ from 'jquery'

// 引入 bootstrap UI框架
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
// 引入 bootstrap 自定义主体样式
// import './assets/scss/custom.scss'

// 引入函数
import { getJsonDataRes } from './utils/tool'

// 设置Cookies的域（同一域名下不同子域名之间共享用户登录信息）
Cookies.domain = window.SITE_CONFIG.domain

const app = createApp(App)
app.use(router)
app.use(store)
app.use(ElementPlus)
// app.use($)
app.provide('$userInfoGlobal', UserInfoGlobal)
app.mount('#app')

// 暴露函数供外界使用
window.getJsonDataRes = getJsonDataRes
