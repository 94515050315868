<template>
  <el-config-provider :locale="locale">
    <router-view />
  </el-config-provider>
</template>

<script setup>
import { computed } from "vue";
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import './assets/js/baidu-popularize'
const locale = computed(() => zhCn)
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this
    let args = arguments
    clearTimeout(timer)
    timer = setTimeout(function () {
      fn.apply(context, args)
    }, delay)
  };
};

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16)
    super(callback)
  }
}
</script>

<style lang="scss" scoped>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif; /*定义全局字体*/
    -webkit-font-smoothing: antialiased; /*渲染规则: 字体抗锯齿。适用于 Chrome 和 Safiri 浏览器*/
    -moz-osx-font-smoothing: grayscale; /*渲染规则: 字体抗锯齿。火狐针对 ios 系统制定的规则*/
    text-align: center; /*定义全局字体居中*/
    color: #2c3e50; /*定义全局字体颜色*/
  }
</style>
